import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from "styled-components";
// import leftArrowDate from "../assets/left-arrow-date.svg";
// import icDate from "../"
import useHorizontalScroll from './useHorizontalScroll';

const Calendar = (props) => {

    const [currentDate, setCurrentDate] = useState(props.value || null)
    const [monthName, setMonthName] = useState("")

    const [blockedDays, setBlockedDays] = useState(props.blocked || [])

    const scrollRef = useHorizontalScroll();

    const [dateList, setDateList] = useState([]);
    const [day, setDay] = useState({});
    const [intervalsLeft, setIntervalsLeft] = useState([]);
    const [intervalsRight, setIntervalsRight] = useState([]);
    const [intervalIdLeft, setIntervalIdLeft] = useState();
    const [intervalIdRight, setIntervalIdRight] = useState();
    
    useEffect(()=>{
        setCurrentDate(props.value || null)
    }, [props])

    const toDayName = (n) => {
        switch (n) {
            case 0:
            return "Saunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        }
    };

    
    const toDaySortName = (n) => {
        switch (n) {
            case 0:
            return "Sun";
        case 1:
            return "Mon";
        case 2:
            return "Tue";
        case 3:
            return "Wed";
        case 4:
            return "Thu";
        case 5:
            return "Fri";
        case 6:
            return "Sat";
        }
    };

    const toMonthName = (n) => {
        switch (n) {
        case 1:
            return "JAN";
        case 2:
            return "FEB";
        case 3:
            return "MAR";
        case 4:
            return "APR";
        case 5:
            return "MAY";
        case 6:
            return "JUN";
        case 7:
            return "JUL";
        case 8:
            return "AUG";
        case 9:
            return "SEP";
        case 10:
            return "OCT";
        case 11:
            return "NOV";
        case 12:
            return "DEC";
        }
    };

    const generateDateList = () => {

        let date = new Date();
        let newDateList = [];
        
        for (let i = 0; i < 365; i++) {
        
            let formattedDate = date.getFullYear() + "-" + ("0" + (date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)

            // console.log(
            //     blockedDays,
            //     formattedDate,
            //     blockedDays.includes(formattedDate)
            // )

            if(blockedDays.includes(formattedDate)){
                newDateList.push({
                    number: date.getDate(),
                    day: toDayName(date.getDay()),
                    daysname: toDaySortName(date.getDay()),
                    isToday: date.setHours(0,0,0,0)==new Date().setHours(0,0,0,0),
                    month: date.getMonth() + 1,
                    year: date.getFullYear(),
                    date: date.toString()
                });   
            }

            date.setDate(date.getDate() + 1);

        }

        return newDateList;

    };

    useEffect(() => {
        const newDateList = generateDateList();
        setDateList(newDateList);
        // console.log(newDateList)
        setDay(newDateList);

        let sd = props.startDate || ""
        let sdate = null

        if(sd==""){
            sdate = new Date()
        }else{
            sdate = new Date(sd)
        }

        let d = sdate.getMonth()+1

        setMonthName( currentDate ? toMonthName(currentDate.getMonth()+1) : toMonthName(d) )
    }, []);

    useEffect(()=>{
        setBlockedDays(props.blocked || [])
    }, [props])

    const autoScroll = (direction) => {
        if (direction === "left") {
        setIntervalIdLeft(
            setInterval(() => {
            document.getElementById("scroll")?.scrollBy(-3, 0);
            }, 5)
        );
        setIntervalsLeft([...intervalsLeft, intervalIdLeft]);
        } else {
        setIntervalIdRight(
            setInterval(() => {
            document.getElementById("scroll")?.scrollBy(3, 0);
            }, 5)
        );
        setIntervalsRight([...intervalsRight, intervalIdRight]);
        }
    };
        
    const clearRunningIntervals = () => {
        intervalsLeft.map((i) => {
        clearInterval(i);
        return null;
        });
        intervalsRight.map((i) => {
        clearInterval(i);
        return null;
        });
    };

    const getElementFromPoint = (x, y) => {
        const element = document.elementFromPoint(x, y);
        return element;
    };


    const handleScroll = () => {
       
        const rect = scrollRef.current.getBoundingClientRect();

        let e = getElementFromPoint(rect.left, rect.top + 100);

        let month = e.getAttribute("data-month")

        if(month)
            setMonthName(month)

    }

    const [isMouseDown, setIsMouseDown] = useState(false);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });
    const handleDragStart = (e) => {
        if (!scrollRef.current) return
      const slider = scrollRef.current;
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const scrollLeft = slider.scrollLeft;
        const scrollTop = slider.scrollTop;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
        setIsMouseDown(true)
        document.body.style.cursor = "grabbing"
    }
    const handleDragEnd = () => {
        setIsMouseDown(false)
        if (!scrollRef.current) return
        document.body.style.cursor = "default"
    }
    const handleDrag = (e) => {
        if (!isMouseDown || ! scrollRef.current) return;
        e.preventDefault();
        const slider = scrollRef.current;
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    }


  return (
    <div style={props.style} className='calender'>

        {/* {window.innerWidth > 10 ? (
            <img
            src={leftArrowDate}
            alt="left"
            style={{
                position: "absolute",
                left: "15px",
                height: "15px",
                cursor: "pointer",
            }}
            id="scroll-left"
            // onClick={() => autoScroll("left")}
            onMouseDown={() => autoScroll("left")}
            onMouseUp={() => {
                clearInterval(intervalIdLeft);
                clearRunningIntervals();
            }}
            onDrag={() => {
                clearInterval(intervalIdLeft);
            }}
            />
        ) : null} */}

        <div className='monthname'>
            <div>
                {monthName.toLowerCase()}<br />
                <img src="/booking-app/ic-date.svg" />
            </div>
        </div>
        
        {dateList.length==0 ? (
            <>
                <p style={{padding: "10px", paddingLeft: "20px"}}>Currently no open slots</p>
            </>
        ) : (
            <div id="scroll" className='content'>
            <div ref={scrollRef} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag} className='innner_content' onScroll={handleScroll} >
  
              {dateList.map((d, index) => (
                  <div
                  key={index}
                  data-month={toMonthName(d.month)}
                  className={`day-details ${d.daysname}-${("0" + d.number).slice(-2)} ${currentDate && (currentDate.setHours(0,0,0,0)==new Date(d.date).setHours(0,0,0,0) ? "active" : "")}`}
                  onClick={(e)=>{
                      let sdt = new Date(d.date)
                      setCurrentDate(sdt)
                      setMonthName(toMonthName(d.month))
                      props.onSelected && props.onSelected(sdt)
                  }}
                  >
                  <span className={`${d.isToday ? "tbold" : ""} dayname`}>{d.isToday ? "Today" : d.daysname}</span>
                  <div className="" />
                  <div
                      onClick={() => setDay(d)}
                      className={`${
                      day.number === d.number &&
                      day.month === d.month &&
                      day.year === d.year
                          ? ""
                          : ""
                      } daydate`}
                  >
                      <span className="">
                      {("0" + d.number).slice(-2)}
                      </span>
                  </div>
                  {/* <span
                      className={`text-xs ${
                      day.number === d.number &&
                      day.month === d.month &&
                      day.year === d.year
                          ? "font-bold text-purple-600"
                          : "font-medium text-purple-400"
                      } ${
                      d.number === 1 ||
                      index === 0 ||
                      (day.number === d.number &&
                          day.month === d.month &&
                          day.year === d.year)
                          ? "visible"
                          : "invisible"
                      }`}
                  >
                      {toMonthName(d.month)}
                  </span> */}
                  </div>
              ))}
            </div>
          </div>
        )}
        

        {/* {window.innerWidth > 10 ? (
            <img
            src={leftArrowDate}
            alt="right"
            className='rarrow'
            // onClick={() => autoScroll("right")}
            onMouseDown={() => autoScroll("right")}
            onMouseUp={() => {
                clearInterval(intervalIdRight);
                clearRunningIntervals();
            }}
            onDrag={() => {
                clearInterval(intervalIdRight);
            }}
            />
        ) : null} */}
    </div>
  )
}

export default Calendar