import { useEffect, useRef, useState } from "react";
// import IcMinus from "../assets/ic-minus.svg"
// import IcPlus from "../assets/ic-plus.svg"

export const Counter = (props) => {

    const isMounted = useRef(false);

    const [count, setCount] = useState(props.value || 0);
    const [max, setMax] = useState(props.max || 999999999999);
    const [min, setMin] = useState(props.min || 0);
    const [step, setStep] = useState(props.step || 1);
    const [isDisabled, setIsDisabled] = useState(props.disabled || false)

    useEffect(()=>{
        setCount(props.value || 0)
        setIsDisabled(props.disabled || false)
        setMax(props.max || 999999999999)
        setMin(props.min || 0)
        setStep(props.step || 1)
    }, [props])

    const incrementCount = () => {
        if(isDisabled) return
        if(count>=parseFloat(max)) return
        setCount(count + parseFloat(step));
    }

    const decrementCount = () => {
        if(isDisabled) return
        if(count<=parseFloat(min)) return
        setCount(count - parseFloat(step));
    }

    useEffect(()=>{
        if(isMounted.current){
            props.onChange && props.onChange(count)
        }else{
            isMounted.current = true;
        }
    }, [count])

    return(
        <div className={`qcounter ${props.className} ${isDisabled ? "qcounter-disabled" : ""}`}>
            <img className={`${count<=parseFloat(min)? "disabled" : ""}`} onClick={decrementCount} src="/booking-app/ic-minus.svg" />
            <div className="qcounter-label">{parseFloat(count)}</div>
            <img className={`${count>=parseFloat(max)? "disabled" : ""}`} onClick={incrementCount} src="/booking-app/ic-plus.svg" />
        </div>
    )

}