import { useEffect, useState } from "react";
import Calendar from "./Calendar";
import Slots from "./Slots";
import { Counter } from "./Counter";
import axios from "axios";

const Main = () => {

    const BASE_URL = (window.location.hostname=="localhost" || window.location.hostname=="192.168.0.110" ? 
        "https://bliskiesmaki.pl" 
        : window.location.protocol + "//" + window.location.host) + "/wp-json/booking-app/v1/";

    const [blockedDays, setBlockedDays] = useState([])
    const [blockedSlots, setBlockedSlots] = useState([])

    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedSlot, setSelectedSlot] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [slotGap, setSlotGap] = useState(30)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [noOfPeople, setNoOfPeople] = useState(0)

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");

    const getDMY = (date) =>{
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '-' + mm + '-' + yyyy;
    }

    const get24Hour = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var timeString = hours + ":" + minutes + ":00";

        return timeString
    }

    const handleSubmit = () => {

        setIsSuccess(false)
        setMessage("")

        if(selectedDate==null){
            setMessage("proszę wpisać wybierz datę")
            return;
        }

        if(selectedSlot==null){
            setMessage("proszę wpisać wybierz godzinę")
            return;
        }

        if(name==""){
            setMessage("proszę wpisać swoje imię")
            return;
        }

        if(email==""){
            setMessage("Proszę wpisać swój adres email")
            return;
        }

        if(phone==""){
            setMessage("Proszę podać swój numer telefonu")
            return;
        }

        if(noOfPeople==0){
            setMessage("Proszę wybrać liczbę osób")
            return;
        }

        setLoading(true);

        var formdata = new FormData()
        formdata.append("name", name)
        formdata.append("email", email)
        formdata.append("phone", phone)
        formdata.append("people", noOfPeople)
        formdata.append("date", getDMY(selectedDate))
        formdata.append("time", get24Hour(selectedSlot.start))

        /*axios.post("https://bliskie.accurite.co.in/wp-json/booking-app/v1/book", {
            name: name,
            email: email,
            phone: phone,
            people: noOfPeople,
            date: getDMY(selectedDate),
            time: get24Hour(selectedSlot.start)
            })*/

        axios.post(BASE_URL + "book", formdata,{
            
        }).then((response) => {

                setLoading(false);

                let data = response.data;
                
                setIsSuccess(true)
                setIsSuccess(data.status)
                setMessage(data.message)

                if(data.status){
                    setEmail("")    
                    setName("")
                    setPhone("")
                    setNoOfPeople("")
                    setSelectedDate(null)
                    setSelectedSlot(null)
                }
                //setMsg(response.data.message)
                
            });
    }

    const [loadingGet, setLoadingGet] = useState(true);
    const [hasAnyError, setHasAnyError] = useState(true);

    
    const getData = () => {

        setLoadingGet(true)

        axios
        .get(BASE_URL + "slot-gap",{
        })
        .then((response)=>{
            
            setLoadingGet(false)

            let data = response.data;
                
            setIsSuccess(data.status)
            
            if(!data.status){
                setMessage(data.message)
                setHasAnyError(true)
            }else{
                setHasAnyError(false)
                setSlotGap(data.data.gap)

                setBlockedDays(data.data.blocked_slots.full_days)
                setBlockedSlots(data.data.blocked_slots.time_gaps)

                setStartDate(
                    data.data.start_date
                )

            }

        })
        .catch((error)=>{
            setLoadingGet(false)
            setIsSuccess(false)
            setHasAnyError(true)
            setMessage("Error, please try again...")
        });
    }

    useEffect(()=>{
        getData()
    }, [])

    return(
        // style={{width:"600px"}}
        <div>
            
            {loadingGet ? <div style={{textAlign:"center"}}>Ładowanie...</div> : (
                hasAnyError ? (
                    <div className={`alert ${isSuccess?"alert-success":"alert-danger"}`}>
                        <p>{message}</p>
                    </div>
                ) : (
                    <>
                        <label style={{paddingTop: "15px", paddingLeft:"15px"}}>Wybierz datę:</label><br /><br />
                        <div className="box">
                            <Calendar
                                value={selectedDate}
                                startDate={startDate}
                                blocked={blockedDays}
                                // style={{paddingLeft:"15px"}}
                                onSelected={(date)=>{
                                    setSelectedDate(date)
                                }} />
                        </div>
                        <br /><br />
                        {blockedDays.length!=0 && (
                            <>
                                <label style={{paddingLeft:"15px"}}>Wybierz godzinę:</label><br /><br />
                                <div className="box">
                                    <Slots
                                        // style={{paddingLeft:"15px"}}
                                        slotMin={slotGap}
                                        openDays={blockedDays}
                                        blocked={blockedSlots.filter((val)=> {
                                            let date = selectedDate || new Date();
                                            return new Date(val.start_time).setHours(0,0,0,0)==date.setHours(0,0,0,0)
                                        } )}
                                        value={selectedSlot}
                                        date={selectedDate}
                                        onSelected={(slot)=>{
                                            setSelectedSlot(slot)
                                        }}
                                    />
                                </div>
                                <br /><br />
                            </>
                        )}
                        
                        <div style={{paddingLeft: "15px", paddingRight:"15px", paddingBottom:"15px"}}>
                            <label>Wprowadź swoje dane:</label><br /><br />
                            <div className="row">
                                <div className="col">
                                    <input type="text" className="form-control mb" placeholder="Imię i nazwisko" value={name} onChange={(e)=>setName(e.target.value)} />
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control " placeholder="Adres email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                                </div>
                            </div>
                            <br />

                            <div className="row">
                                <div className="col">
                                    <input type="text" className="form-control mb" placeholder="Numer telefonu" value={phone} onChange={(e)=>setPhone(e.target.value)} />
                                </div>
                                <div className="col">
                                    <div className="form-control cnter">
                                        Liczba osób
                                        <Counter value={noOfPeople} max={3} onChange={(val)=>{
                                            setNoOfPeople(val)
                                        }} />
                                    </div>
                                </div>
                            </div>

                            {message && (
                                <div className={`alert ${isSuccess?"alert-success":"alert-danger"}`}>
                                    <p>{message}</p>
                                </div>
                            )}

                            <br /><br />
                            <div style={{textAlign: "center"}}>
                                <button onClick={handleSubmit} type="button" disabled={loading} className="btnapp">{loading ? "Ładowanie..." : "Zarezerwuj degustację"}</button>
                            </div>
                            <br /><br />
                        </div>
                    </>
                )
            )}
            
        </div>
    )

}

export default Main;