import './App.css';
import Main from './Components/Main';

function App() {
  return (
    <Main />
  );
}

export default App;
