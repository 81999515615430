import { useEffect, useRef, useState } from "react";
import useHorizontalScroll from "./useHorizontalScroll";

const Slots = (props) => {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [timeSlots, setTimeSlots] = useState([]);
    const [slotMin, setSlotMin] = useState(props.slotMin || 60)

    const [blockedDays, setBlockedDays] = useState(props.blocked || [])
    const [blockedSlots, setBlockedSlots] = useState(props.blocked || [])

    const generateTimeSlots = () => {

        // console.log("Blocked Time", blockedSlots)

        let blockHours = [];

        blockedSlots.map((val)=>{

            let start = new Date(val.start_time);
            let end = new Date(val.end_time);

            let sm = Math.round(start.getMinutes() / slotMin) * slotMin
            let em = Math.round(end.getMinutes() / slotMin) * slotMin

            blockHours.push({
                s: start.getHours(),
                sm: sm,
                e: end.getHours(),
                em: em
            })
        })

        let startTime = new Date()

        let date = new Date()

        let selDate = selectedDate;

        if(new Date().setHours(0,0,0,0)==selDate.setHours(0,0,0,0)){
           
            let min = date.getMinutes();
            min = Math.round(min / slotMin) * slotMin

            startTime = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                date.getHours(),
                min,
                date.getSeconds()
            );

        }else{
            startTime = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),0,0,0
            );
        }

        let endTime = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23, 60, 0);

        const slots = [];

        let index = 0;

        while (startTime < endTime) {

            //console.log("startTime H",getAmPmTime(startTime), startTime.getHours(), startTime.getHours() >= 1 && startTime.getHours() <= 7)

           if(
            // blockedSlots.filter((val)=> {
            //     // 21:48:00 > 21:00:00 && 22:35:00 < 21:00:00
            //     return ( new Date(val.start_time)>startTime || new Date(val.end_time)<=startTime )
            // } ).length==blockedSlots.length
            startTime.getHours() >= 8 && startTime.getHours() < 20
            // blockedSlots.every(
            //     (blockedSlot) =>
            //         startTime < new Date(blockedSlot.start_time) ||
            //         startTime >= new Date(blockedSlot.end_time)
            // )
           ){

                // console.log(blockHours.filter((bs)=>{
                //     console.log("bl-s", startTime.getHours(), bs.s, startTime.getMinutes(), bs.sm, "bl-e", startTime.getHours(), bs.e, startTime.getMinutes(), bs.em)
                //     return true
                // }))
                
                // if(checkTime(blockHours, startTime.getHours(), startTime.getMinutes(), startTime )){
                //     slots.push({
                //         index: index++,
                //         start: startTime,
                //         end: new Date(startTime.getTime() + slotMin * 60 * 1000),
                //     });
                // }
                
                // 21:48:00 > 21:00:00 && 22:35:00 < 21:00:00

                // console.log(blockedSlots.filter((val)=> {
                //     return ( new Date(val.start_time)>startTime || new Date(val.end_time)<=startTime )
                // } ))

                if(blockedSlots.length==0){
                    slots.push({
                        index: index++,
                        start: startTime,
                        end: new Date(startTime.getTime() + slotMin * 60 * 1000),
                    });
                }else{
                    if(
                        !(blockedSlots.filter((val)=> {
                            return ( new Date(val.start_time)>startTime || new Date(val.end_time)<=startTime )
                        } ).length==blockedSlots.length)
                    ){
                        slots.push({
                            index: index++,
                            start: startTime,
                            end: new Date(startTime.getTime() + slotMin * 60 * 1000),
                        });
                    }
                }
                

           }
            
            startTime = new Date(startTime.getTime() + slotMin * 60 * 1000);
        }

        setTimeSlots(slots);
    };

    const checkTime = (blockHours, sh, sm, st) => {

        for(var i = 0; i < blockedSlots.length; i++){
            let val = blockedSlots[i]

            // console.log( new Date(val.start_time),new Date(val.end_time), st, new Date(val.start_time).getTime(), st.getTime(), new Date(val.start_time).getTime()>=st.getTime() )

            if( new Date(val.start_time).getTime()>=st.getTime() && new Date(val.end_time).getTime()<=st.getTime() ){
                return true;
            }

        }

        /*for(var i = 0; i < blockHours.length; i++){
            let bh = blockHours[i]

            console.log("Loop",bh.s, sh, bh.sm, sm, bh.s>=sh && bh.sm>=sm, getAmPmTime(st))

            if( new Date(val.start_time)>startTime || new Date(val.end_time)<=startTime ){
                return false
            }

            //if(bh.s>=sh && bh.sm<=sm) return false
            //if(bh.e>=sh && bh.em>=sm) return false
        }*/

        return true
    }

    useEffect(() => {
        generateTimeSlots();
    }, [selectedDate]);

    useEffect(()=>{
        setSelectedDate(props.date || new Date())
        setSlotMin(props.slotMin || 60)
        setSelectedTimeSlot(props.value || null)
        setBlockedSlots(props.blocked || [])
        setBlockedDays(props.openDays || [])
    }, [props])

    const getAmPmTime = (date) => {
        return date.toLocaleString([], {
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const scrollRef = useHorizontalScroll();

    const handleScroll = () => {
       
    }

    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)

    const [isMouseDown, setIsMouseDown] = useState(false);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });
    const handleDragStart = (e) => {
        if (!scrollRef.current) return
      const slider = scrollRef.current;
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const scrollLeft = slider.scrollLeft;
        const scrollTop = slider.scrollTop;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
        setIsMouseDown(true)
        document.body.style.cursor = "grabbing"
    }
    const handleDragEnd = () => {
        setIsMouseDown(false)
        if (!scrollRef.current) return
        document.body.style.cursor = "default"
    }
    const handleDrag = (e) => {
        if (!isMouseDown || ! scrollRef.current) return;
        e.preventDefault();
        const slider = scrollRef.current;
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    }

    return(
        <div style={props.style} className="slots">
            <div ref={scrollRef} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag} className='slot_content' onScroll={handleScroll} >
                {timeSlots.map((val, key)=>{
                    return(
                        <div
                            className={`slot ${selectedTimeSlot && selectedTimeSlot.index==val.index ? "active" : "" }`}
                            onClick={(e)=>{
                                setSelectedTimeSlot(val)
                                props.onSelected && props.onSelected(val)
                            }}
                            key={`slot-${key}`}
                        >{getAmPmTime(val.start)}</div>
                    )
                })}
            </div>
        </div>
    )

}

export default Slots;